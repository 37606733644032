.app-table {
  &__header {
    border-bottom: 1px solid var(--color-grey-border4);
  }

  &__header-title {
    max-width: 300px;
    width: 50%;
    border-right: 1px solid var(--color-grey-border4);
    &:first-child {
      max-width: 100%;
      width: 100%;
    }
  }
  &__hidden {
    opacity: 0;
    transition: .3s;
  }
  &__row {
    border-bottom: 1px solid var(--color-grey-border5);
    transition: .3s;
    &:hover {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);

      .app-table__hidden {
        opacity: 1;
      }
    }
    &.active {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

      .app-table__hidden {
        opacity: 1;
      }
    }
  }

  &__col {
    max-width: 300px;
    width: 50%;
    &:first-child {
      max-width: 100%;
      width: 100%;
    }
  }

  &__subtitle {
    -moz-transition: max-height 0.4s ease;
    -webkit-transition: max-height 0.4s ease;
    -o-transition: max-height 0.4s ease;
    transition: max-height 0.4s ease;
    overflow: hidden;
    // max-height: 0;
  }

  &__sub-open {
    svg {
      transition: .3s;
      transform: rotate(-90deg);
    }
    &.active {
      svg {
        transform: rotate(0deg);
      }
    }
  }
}

.app-table__new-row {
  -moz-transition: max-height 0.2s ease;
  -webkit-transition: max-height 0.2s ease;
  -o-transition: max-height 0.2s ease;
  transition: max-height 0.2s ease;
  overflow: hidden;
  max-height: 0;
}