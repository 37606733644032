$app-menu-width: 52px;

.app-menu {
  background: var(--color-blue-light);
  width: $app-menu-width;
  height: 100vh;
  z-index: 6;

  &__logo {
    width: 30px;
    height: 34px;
  }

  &__item,
  &__bottom {
    height: $app-menu-width;
    transition: .4s;

    &.svg-stroke  {
      svg path {
        stroke: var(--color-grey);
      }
    }
    &.svg-fill  {
      svg path {
        fill: var(--color-grey);
      }
    }
    
    &.active {
      background: #ffffff;
      &.svg-stroke {
        svg path {
          stroke: var(--color-blue);
        }
      }
      &.svg-fill  {
        svg path {
          fill: var(--color-blue);
        }
      }
    }
  }

  &__bottom {
      svg path {
        stroke: var(--color-grey-dark);
    }
  }
}

.app-form {
  &__upload {
    input {
      position: absolute;
      opacity: 0;
    }
    fieldset {
      min-width: 0;
      padding: 0;
      margin: 0;
      border: 0;
    }
  }
  &__upload-avatar {
    width: 120px;
    height: 120px;
    border-radius: 16px;
    background: var(--color-violet);
    color: #ffffff;
    font-size: 96px;
  }
}

.app-sidebar {
  background: #FFFFFF;
  width: 238px;
  border-right: 1px solid var(--color-grey-border9);
}

.add-back-1 {
  position: relative;
}

.add-back-1 img{
  position: absolute;
  // top: -20px;
  top: -160px;
  left: 250px;

  z-index: -10;
}

.add-back-2 {
  position: relative;
  height: 100vh;
}

.add-back-2 img{
  position: absolute;
  // bottom: 0px;
  bottom: -300px;
  left: -675px;
  z-index: -10;
  // transform: scale(0.7);
}

.circle {
  border-radius: 50% !important;
}