.password-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

/* Стили для инпута */
#password-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Стили для контейнера иконки */
.password-toggle {
    position: absolute;
    right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

/* Стили для иконки глазка */
.icon-eye {
    width: 20px;
    height: 20px;
    // background: url('eye-icon.png') no-repeat;
    background-size: cover;
    transition: transform 0.2s;
}

/* Поворот иконки при активации */
#password-input:focus + .password-toggle .icon-eye {
    transform: rotate(-45deg);
}
