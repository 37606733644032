.project-row {
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-right: -10px;
}

.project-col {
  width: 240px;
  padding-left: 10px;
  padding-right: 10px;
}

.card-project {
  height: 180px;
  border-radius: 12px;
  text-decoration: none;

  &__top {
    svg {
      path {
        stroke: #fff;
      }
    }
  }
}

.card-project__add {
  background: var(--color-blue);


  svg {
    width: 80px;
    height: 80px;
    path {
      stroke: #fff;
    }
  }
}

.card-project-image {
  position: absolute;
  border-radius: 12px;
  height: 180px;
  width: 220px;
  z-index: -1;

  object-fit: cover;
}