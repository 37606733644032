.circle-20 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.circle-24 {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.circle-32 {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.circle-36 {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.app-border {
  background: var(--color-grey-border);
  height: 1px;
}
.app-border2 {
  background: var(--color-grey-border2);
  height: 1px;
}
.app-border3 {
  background: var(--color-grey-border3);
  height: 1px;
}

.app-border8 {
  background: var(--color-grey-border8);
  height: 1px;
}

.app-border9 {
  background: var(--color-grey-avatar);
  height: 1px;
}

.app-border10 {
  background: var(--color-grey-input2);
  height: 1px;
}

.bg-violet {
  background: var(--color-violet) !important;
}

.bg-grey2 {
  background: var(--color-grey2) !important;
}

.bg-dark2 {
  background: var(--color-dark2) !important;
}

.bg-blue-100 {
   background: var(--color-blue-btn) !important;
}

.bg-blue-btn {
  color: var(--color-blue-btn2) !important;
}

.bg-error {
  background: var(--color-error) !important;
}

.bg-work {
  background: var(--color-error-btn) !important;
}

.bg-orange {
  background: var(--color-orange) !important;
}

.bg-notification {
  background: var(--color-notification) !important;
}

.bg-success {
  background: var(--color-success) !important;
}

.bg-error2 {
  background: var(--color-error-text) !important;
}

.bg-orange {
  background: var(--color-orange) !important;
}

.bg-light {
  background: var(--color-bg) !important;
}

.bg-light2 {
  background: var(--color-bg2) !important;
}

.bg-light3 {
  background: var(--color-grey-bg) !important;
}

.color-orange {
  color: var(--color-orange) !important;
}

.color-primary {
  color: var(--color-primary) !important;
}

.color-dark {
  color: var(--color-dark) !important;
}

.color-grey {
  color: var(--color-grey) !important;
}

.color-blue {
  color: var(--color-blue) !important;
}

.color-grey3 {
  color: var(--color-grey3) !important;
}

.color-grey4 {
  color: var(--color-grey4) !important;
}

.color-grey5 {
  color: var(--color-grey-text5) !important;
}

.color-grey7 {
  color: var(--color-grey-text7) !important;
}

.color-grey-title {
  color: var(--color-grey-title) !important;
}

.color-grey-table {
  color: var(--color-grey-table) !important;
}

.color-grey-task {
  color: var(--color-grey-task) !important;
}

.color-grey-btn {
  color: var(--color-grey-btn) !important;
}

.color-grey-text {
  color: var(--color-grey-text) !important;
}

.color-grey-text2 {
  color: var(--color-grey-text2) !important;
}

.color-grey-text3 {
  color: var(--color-grey-text3) !important;
}

.color-grey-text4 {
  color: var(--color-grey-text4) !important;
}

.color-grey-text6 {
  color: var(--color-grey-text6) !important;
}

.color-grey-secondary {
  color: var(--color-grey-secondary) !important;
}

.color-task {
  color: var(--color-task) !important;
}

.color-grizzle {
  color: var(--color-grizzle) !important;
}

.color-hoar {
  color: var(--color-hoar) !important;
}

.color-error {
  color: var(--color-error) !important;
}

.color-error2 {
  color: var(--color-error-text) !important;
}

.color-red2 {
  color: var(--color-red2) !important;
}

.color-success {
  color: var(--color-success) !important;
}

.color-notification {
  color: var(--color-notification) !important;
}

.color-white {
  color: #FFFFFF !important;
}

.color-cabinet {
  color: var(--color-cabinet) !important;
}

.svg-w--8 svg {
  width: 8px;
  height: 8px;
}

.svg-w--12 svg {
  width: 12px;
  height: 12px;
}

.svg-w--16 svg {
  width: 16px;
  height: 16px;
}

.svg-w--20 svg {
  width: 20px;
  height: 20px;
}

.svg-w--24 svg {
  width: 24px;
  height: 24px;
}

.svg-w--36 svg {
  width: 36px;
  height: 36px;
}

.svg-stroke-blue svg {
 path {
   stroke: var(--color-blue);
 }
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-8 {
  border-radius: 8px !important;
}

.app-line {
  position: relative;
  padding-left: 4px;
  &:before {
    content: '';
    height: 100%;
    width: 1px;
    background: var(--color-notification);
    position: absolute;
    left: -1px;
    top: 0;
  }
  &-error {
    &:before {
      background: var(--color-error);
    }
  }
  &-success {
    &:before {
      background: var(--color-success);
    }
  }
}

.border-grey2 {
  border: 1px solid var(--color-grey2);
}

.border-blue {
  border: 1px solid var(--color-blue3);
}

.shadow-input {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 5px;
  padding: 4px;
}

.minW-200 {
  min-width: 200px;
}

.maxW-400 {
  max-width: 400px;
}

.maxW-160 {
  max-width: 160px;
}