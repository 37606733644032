.app-card__newcard {
  opacity: 0;
}
.app-boards__column:not(.active) {
  &:hover {
    .app-card__newcard {
      opacity: 1;
    }
  }
}
.app-card__newtask-add {
  border-radius: 4px;
  transition: .2s;

  &:hover {
    background: rgba(183, 180, 180, 0.15);
  }
}

.app-card__new {
  border-left: 4px solid var(--color-task);

  &.hidden {
    opacity: 0;
    z-index: -1;
    width: 0;
    height: 0;
    position: relative;
  }

  input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}