.app-menu-open--command,
.app-menu-open--task,
.app-menu-open--news {
  opacity: 0;
  transition: opacity .2s;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 0;
  height: 0;
  overflow: hidden;

  &.open {
    opacity: 1;
    z-index: 1;
    width: max-content;
    height: 100vh;
  }
}

.app-menu-open--list {
  width: 270px;
  background: #ffffff;
  backdrop-filter: blur(2px);
  height: 100%;
}

.app-menu-open--editing {
  width: 100vw;
  max-width: 737px;
  background: #ffffff;
  border-left: 1px solid var(--color-grey-border8);
  overflow: auto;
}

.app-menu-open {
  height: 100vh;
  position: absolute;
  left: $app-menu-width;
 // width: 737px;
  top: 0;
  z-index: 5;
  transform: translateX(-1500px);
  transition: .4s;

  &.open {
    transform: translateX(0%);

  }

  &__item {
    min-height: 44px;
    transition: .2s;

    &.active {
      background: var(--color-violet-light);
      color: var(--color-navy-blue);
    }
  }

  &__avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: var(--color-grey-avatar);
  }

  &__circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: #ffffff;
  }

  &__status {
    position: relative;
    &:before {
      content: '';
      height: 100%;
      width: 1px;
      background: var(--color-notification);
      position: absolute;
      left: -1px;
      top: 0;
    }
    &.error {
      &:before {
        background: var(--color-error);
      }
    }
    &.success {
      &:before {
        background: var(--color-success);
      }
    }
  }
}