.app-tariff {
  border: 1px solid var(--color-grey-border8);
  border-radius: 12px;

  &__btn {
    padding: 12px 48px;
  }
}

.app-tariff__sidebar {
  border: 1px solid var(--color-grey-border6);
  border-radius: 4px;

  p {
    color: var(--color-grey-text8);
    // font-size: 13px;
  }

  &-row {
    border-bottom: 1px solid var(--color-grey-input2);
  }

  .btn {
    height: 36px;
    padding: 0;
  }
}