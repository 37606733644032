@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter/Inter-Regular.eot');
	src: local('Inter Regular'), local('Inter-Regular'),
	url('../fonts/Inter/Inter-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Inter/Inter-Regular.woff') format('woff'),
	url('../fonts/Inter/Inter-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter/Inter-Medium.eot');
	src: local('Inter Medium'), local('Inter-Medium'),
	url('../fonts/Inter/Inter-Medium.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Inter/Inter-Medium.woff') format('woff'),
	url('../fonts/Inter/Inter-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter/Inter-SemiBold.eot');
	src: local('Inter SemiBold'), local('Inter-SemiBold'),
	url('../fonts/Inter/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Inter/Inter-SemiBold.woff') format('woff'),
	url('../fonts/Inter/Inter-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
