.overlay {
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.45);
  z-index: -1;
  opacity: 0;
  transition: 0.2s;

  &-scroll {
    overflow-y: auto;
    overflow-x: visible;

    &::-webkit-scrollbar {
      width: 4px;
      background-color: var(--color-grey-secondary);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-scroll);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--color-scroll);
    }
  }

  .overlay-wrapper {
    border-radius: 8px;
    max-height: 100%;
  }

  &-wrap {
    width: calc(100% - 40px);
    max-width: 800px;
    max-height:  600px;
    background: #ffffff;
    transition: 0.2s;
    border-radius: 8px;
    height: auto;
    overflow: auto;
  }

  &-wrap__content {
    // border-radius: 40px;
    overflow: auto;
    //padding-right: 16px;
    max-height: 100%;
  }
  &-close {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
  }

  &-close--relative {
    position: relative;
    right: auto;
    top: auto;
    border-radius: 0;
    background: none;
  }
}

.overlay-show {
  opacity: 1;
  z-index: 999;

  .overlay-wrap {
    margin-right: 0;
  }
}

.overlay-upgrade {
  .overlay-wrap__content {
    padding: 20px 36px 105px 36px;
  }
}

.overlay-profile {
  .overlay-wrap {
    max-height: calc(100% - 28px);
    max-width: 762px;
    padding: 0;
  }
  .overlay-wrap__content {
    padding: 0;
  }
  .btn.btn-error {
    min-width: 180px;
  }
}

.overlay-status {
  .overlay-wrap {
    max-width: 244px;
    border-radius: 8px;
  }
}

.overlay-task {
  .overlay-wrap {
    border-radius: 8px;
  }
  .overlay-scroll {
    max-height: 512px;
  }
  .overlay-header {
    background: var(--color-grey-bg);
    border-bottom: 1px solid var(--color-grey-border6);
    border-radius: 8px 8px 0 0;
  }
  .overlay-wrap__content-top {
    border-bottom: 1px solid var(--color-grey-border6);
    // div:first-child {
    //   border-right: 1px solid var(--color-grey-border6);
    // }
  }

  .overlay-wrap__content-footer {
    border-radius: 0 0 8px 8px;
  }
  .overlay-wrap__content-avatar {
    .app-dropdown__avatar {
      margin-left: -12px;
    }
  }
}

.overlay-border-right {
  border-right: 1px solid var(--color-grey-border6);
}

.overlay-tariff {
  .overlay-wrap {
    max-width: 500px;
    overflow: visible;

    .app-select {
      min-width: 200px;
    }
  }
  .tariff-result--economy {
    background: #E3E8FC;
    color: #2C4CE3;
    border-radius: 0 0 4px 4px;
    padding: 4px 12px;
  }
}

.overlay-add,
.overlay-invite {
  .overlay-wrap {
    max-width: 520px;
  }
  .overlay-close {
    width: 40px;
    height: 40px;
  }
}

.overlay-task-view {
  width: calc(100% - 40px);
  max-width: 1286px;
  max-height: calc(100% - 48px);
  background: #ffffff;
  transition: 0.2s;
  border-radius: 40px;
  height: auto;
}