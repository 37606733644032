.app-avatar__list {
  &-item {
    margin-right: -8px;
    outline: 1px solid #ffffff;
    border-radius: 50%;
    width: 36px;
    height: 36px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.app-avatar__small {
  width: 32px;
  height: 32px;
  background: #B2B459;
  border-radius: 2px;
  color: #FFFFFF;
}