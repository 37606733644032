.app-command {
  &__table-column {
    width: 33.33%;
  }

  &__table-head {
    border-bottom: 1px solid var(--color-grey-border8);
  }

  &__table-row {
    border-bottom: 1px solid var(--color-grey-border8);
    &:last-child {
      border-bottom: none;
    }
  }
}