input {
  border-radius: 4px;
  border: none;
  font-weight: $font-weight;
  appearance: none;
  // color: var(--color-grizzle);

  &::placeholder {
    color: var(--color-grizzle);
    font-weight: $font-weight;
  }

  &:focus,
  &:active {
    outline: none;
  }
}

textarea {
  border-radius: 4px;
  border: none;
  font-weight: $font-weight;
  appearance: none;
  color: var(--color-grizzle);

  &::placeholder {
    color: var(--color-grizzle);
    font-weight: $font-weight;
  }

  &:focus,
  &:active {
    outline: none;
  }
}


.input-search {
  border: 1px solid var(--color-grizzle);
  border-radius: 4px;
}

.input-basic {
  input {
    border: 1px solid var(--color-grey-input);
    min-height: 45px;
    padding: 8px;
  }
}

.input-bas {
  border: 1px solid var(--color-grey-dark2);
  min-height: 45px;
  padding: 12px;
  color: var(--color-grey-dark2);
  border-radius: 4px;
}

.input-grey {
  input {
    border: 1px solid var(--color-grey-border3);
    color: var(--color-primary);
    min-height: 33px;
    padding: 8px 12px;

    &:active,
    &:focus {
      border: 1px solid var(--color-blue-btn2);
    }
  }
}

.input-grey2 {
  background: var(--color-grey-input2);
  svg {
    path {
      stroke: var(--color-grey-task);
    }
  }
  input {
    background: inherit;
    color: var(--color-grey-task);
    min-height: auto;
    &::placeholder {
      color: var(--color-grey-task);
      @include font-little();
    }
  }
}

.app-boards__input input {
  border: 1px solid var(--color-task);
  border-radius: 4px;

  &::placeholder {
    color: var(--color-grey-avatar);
  }
}
.overlay-status__input input {
  border: 1px solid var(--color-task);
  border-radius: 4px;
  color: var(--color-primary);
  padding: 8px 12px;


  &::placeholder {
    color: var(--color-primary);
  }
}

input.app-textarea {
  background: none;
  border: none;
  height: 24px;
  resize: none;
  color: var(--color-hoar);
  &::placeholder {
    color: var(--color-hoar);
  }
  &:focus {
    outline: none;
  }
}

.input-task-add {
  color: var(--color-primary);
  &::placeholder {
    color: var(--color-grey-avatar);
  }
}