.app-checkbox {
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  label{
    display: inline-flex;
    align-items: center;
    user-select: none;

    &::before {
      content: '';
      display: inline-block;
      margin: 4px 16px 4px 4px;
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid #000000;
      border-radius: 2px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }
  }
  .app-checkbox__input:not(:disabled):not(:checked)+label:hover::before {
    border-color: var(--color-blue3);
  }
  .app-checkbox__input:not(:disabled):active+label::before {
    border-color: var(--color-blue3);
  }
  .app-checkbox__input:focus+label::before {
    border-color: var(--color-blue3);
  }

  .app-checkbox__input:focus:not(:checked)+label::before {
    border-color: #000;
  }
  .app-checkbox__input:checked+label::before {
    border-color: var(--color-blue3);
    background-color: var(--color-blue3);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }
  .app-checkbox__input:disabled+label::before {
    background-color: #000;
  }
}

