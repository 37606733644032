.app-tooltip {
  &__message {
    background: var(--color-primary);
    border-radius: 4px;
    color: #FFFFFF;
    position: absolute;
    top: calc(100% + 8px);
    width: max-content;
    transition: .4s;
    z-index: -1;
    opacity: 0;
    overflow: hidden;
    height: 0;

    &:before {
      position: absolute;
      content: '';
      top: -4px;
      left: 50%;
      margin-left: -4px;
      //background: url('../img/tooltip.svg') 0 0 no-repeat;
      width: 8px;
      height: 4px;
    }
  }

  &__right {
    .app-tooltip__message {
      top: 50%;
      margin-top: -12.5px;
      left: calc(100% + 12px);
      background: var(--color-tooltip);
      border-right: 2px;
    }
  }
}

.app-tooltip:hover {
  .app-tooltip__message {
    opacity: 1;
    z-index: 3;
    height: auto;
  }
}

.app-tooltip__data {
  .app-tooltip__btn {
    width: 24px;
    height: 24px;
    outline: 0.5px solid #878787;
    border-radius: 4px 0 0 4px;
    box-sizing: border-box;
    &:hover {
      outline: 1px solid #152CAD;
    }
  }
}

.app-tooltip__menu {
  &:hover {
    .app-tooltip__btn {
      background: var(--color-grey-tooltip);
      border-radius: 4px;
    }
  }
  .app-tooltip__message {
    //padding: 12px;
    background: #FFFFFF;
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.25), 0px 1px 5px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    max-width: 276px;
    color: var(--color-primary);
  }
  &.hidden {
    opacity: 0;
    z-index: -1;
    width: 0;
    height: 0;
    position: absolute;
  }
}

.app-tooltip__subtitle {
  border: 1px solid var(--color-grey-task);
  border-radius: 4px;

  &:hover {
    .app-tooltip__subtitle-text {
      color: var(--color-blue-btn);
    }

    .app-tooltip__subtitle-icon svg {
      path {
        fill: var(--color-blue-btn);
      }
    }
  }
}

.app-tooltip__status {
  border-radius: 2px;

  .app-tooltip__btn {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    transition: .2s;
  }

  &--open {
    .app-tooltip__btn {
      background: var(--color-blue2);
      &:hover {
        outline: 1px solid var(--color-blue2);
        outline-offset: 2px;
      }
    }
  }

  &--work {
    .app-tooltip__btn {
      background: var(--color-error);
      &:hover {
        outline: 1px solid var(--color-error);
        outline-offset: 2px;
      }
    }
  }

  &--review {
    .app-tooltip__btn {
      background: var(--color-success);
      &:hover {
        outline: 1px solid var(--color-success);
        outline-offset: 2px;
      }
    }
  }
  &--close {
    .app-tooltip__btn {
      background: var(--color-notification);
      &:hover {
        outline: 1px solid var(--color-notification);
        outline-offset: 2px;
      }
    }
  }
  &--none {
    .app-tooltip__btn {
      background: var(--color-task);
      &:hover {
        outline: 1px solid var(--color-task);
        outline-offset: 2px;
      }
    }
  }
}

.app-tooltip__close-task {
  &:hover {
    svg path {
      stroke: var(--color-success);
    }
  }
}

.app-menu .no-active {
  opacity: 0.4;
}