:root {
    --color-primary: rgba(0, 0, 0, 1);
    --color-dark: rgba(63, 63, 63, 1); //#3f3f3f
    --color-dark2: rgba(102, 102, 102, 1); //#666666

    --color-tooltip: #414141;

    --color-grey: rgba(184, 181, 181, 1); //#b8b5b5
    --color-grey2: #e7ebee; //rgba(231, 235, 238, 1)
    --color-grey3: rgba(158, 158, 158, 1); //#9e9e9e
    --color-grey4: rgba(141, 141, 141, 1); //#8d8d8d
    --color-grey-secondary: #C1BABA;
    --color-grey-text: #B5B5B5;
    --color-grey-text2: #ABABAB;
    --color-grey-text3: #9B9B9B;
    --color-grey-text4: #AFAFAF;
    --color-grey-text5: #A8A8A8;
    --color-grey-text6: #A6A6A6;
    --color-grey-text7: #969696;
    --color-grey-text8: #727272;
    --color-grey-input: #B1AFAF;
    --color-grey-avatar: #D9D9D9;
    --color-grey-dark: #737373;

    --color-grey-dark2: #353535;


    --color-grey-border: rgba(233, 233, 233, 1); //#e9e9e9
    --color-grey-border2: #BEBEBE;
    --color-grey-border3: #E2E2E2;
    --color-grey-border4: #CBCBCB;
    --color-grey-border5: #F1F1F1;
    --color-grey-border6: #DFDFDF;
    --color-grey-border7: #F2F2F2;
    --color-grey-border8: rgba(224, 224, 224, 1);//#e0e0e0
    --color-grey-border9: #EDEDED;
    --color-grey-image: rgba(169, 169, 169, 1);
    --color-grey-border-card: rgba(222, 218, 218, 1);//#dedada
    --color-grey-border-card2: #DADADA;
    --color-grey-task: #878787;
    --color-grey-btn: #C0C0C0;
    --color-grey-tooltip: #C9C9C9;
    --color-grey-input2: #E8E8E8;
    --color-task: rgba(183, 180, 180, 1); //#b7b4b4
    --color-grey-title: #818181;
    --color-grey-table: #999999;
    --color-grey-bg: #EFF0F3;

    --color-cabinet: #3B607F;

    --color-bg: #F7F8FE;
    --color-bg2: #F7F9FF;
    --color-scroll: #BDDBFF;

    --color-grizzle: #A2A2A2;
    --color-hoar: #C2C2C2;

    --color-blue: #1E3EFE;
    --color-blue-dark: #234D84;


    --color-blue2: #152CAD;
    --color-blue3: #1a67fd;
    --color-blue4: #86D4F9;
    --color-blue5: rgba(43, 185, 253, 1);//#2bb9fd

    --color-blue-btn: rgba(21, 44, 173, 1); //#152cad
    --color-blue-btn2:  #3754FA;
    --color-navy-blue: rgba(73, 97, 232, 1);//#4961e8
    --color-blue-mark: #0067A0;
    --color-mark-light: #D0DFE8;

    --color-board: #F7F8FD;

    --color-blue-light: #E8F5FD;
    --color-sky: rgba(212, 230, 240, 1);

    --color-violet: #7D2BFD;
    --color-violet-light: #DDE2FF;

    --color-orange: #FAA843;
    --color-orange-light: #FEF2E3;

    --color-error: #FA4202;
    --color-error-btn: #FD5656;
    --color-error-btn2: #F6CCCC;

    --color-error-text: #EF0000; //rgb(239, 0, 0)
    --color-red: #EC5050;
    --color-red2: #FF0000;
    --color-red-light: #FCDCDC;
    --color-success: #5CAD1D;
    --color-notification: #40487A;


    --color-green: #408D05;
    --color-green2: #76BE64; //rgba(118, 190, 100, 1)
    --color-green-light: #E4F9D5;
}






