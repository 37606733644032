.btn {
    @include font-basic();
    cursor: pointer;
    font-family: $font-primary;
    font-weight: $font-weight-medium;
	font-style: $font-style;
    color: var(--color-primary-text);
    text-align: center;
    text-decoration: none;
    user-select: none;
    border-radius: 4px;
    background: none;
    border: none;
    width: max-content;
    padding: 0px 24px;
    height: 40px;
    transition: 0.2s;
}

.btn-blue {
  outline: 1px solid var(--color-blue);
  color: var(--color-blue);
}

.btn-blue2 {
    background: var(--color-blue5);
    color: #ffffff;
}

.btn-primary {
    background: var(--color-blue);
    color: #ffffff;

}

.btn-orange {
    border: 1px solid var(--color-orange);
    color: var(--color-orange);

    svg path {
        stroke: var(--color-orange);
    }
}

.btn-blue-light {
    background: var(--color-blue4);
    color: #fff;
}

.btn-blue-dark {
    background: var(--color-blue-btn2);
    color: #ffffff;
}

.btn-error {
    color: var(--color-error-btn);
    background: var(--color-error-btn2);
}

.btn-grey {
    background: var(--color-task);
    color: #ffffff;
    border-radius: 4px;
}

.btn-grey2 {
    background: var(--color-grey-input2);
    color: var(--color-dark);
    border-radius: 4px;
}

.btn-height-max {
    height: max-content;
}
.btn-padding-revert {
    padding: revert;
}

.btn-w-100 {
    width: 100%;
}

.btn.w-100 {
    width: 100%;
}

.btn.h-max {
    height: max-content;
}

.btn-text-primary {
    @include font-basic();
}

.btn.text-small {
    @include font-small();
}

.btn-border-none {
    outline: none;
}
