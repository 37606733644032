.h-100 {
  height: 100%;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.w-max {
  width: max-content;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.svg-block {
  svg {
    display: block;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.position-relative {
  position: relative;
}

.image-contain {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}

.hv-100 {
  height: 100vh;
}


