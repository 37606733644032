.app-notification {
  transition: .4s;
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  right: -320px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: 0;
  overflow: auto;
  background: #fff;
  width: 320px;

  &.open {

    right: 0;
    height: 100vh;
    transition: .4s;
  }


  &__status {
    position: relative;
    &:before {
      content: '';
      height: 100%;
      width: 1px;
      background: var(--color-notification);
      position: absolute;
      left: -1px;
      top: 0;
    }
    &.error {
      &:before {
        background: var(--color-error);
      }
    }
    &.success {
      &:before {
        background: var(--color-success);
      }
    }
  }
}