*, ::after, ::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: $font-primary;
    font-weight: $font-weight;
	font-style: $font-style;
    color: var(--color-primary-text);
    position: relative;
    margin: 0;
    padding: 0;
    background-color:#fff;
    overflow: hidden;
}

[contenteditable] {
    outline: 0px solid transparent;
}

h6, h5, h4, h3, h2, h1, p, a, label, input, span, li, ol, ul, textarea {
    font-family: $font-primary;
    font-weight: $font-weight;
}

h1 {
    @include font-h1();
}

h2,
.text-h2{
    @include font-h2();
}

h3,
.text-font-h3 {
    @include font-h3();
}

label, p, li, input, a {
    @include font-basic();
}

.text-font-h3 {
    @include font-h3();
}

.text-title-large {
    @include font-title-large();
}

.text-primary {
    @include font-basic();
}

.text-small {
    @include font-small();
}

.text-little {
    @include font-little();
}

.text-medium {
    @include font-medium();
}

.text {
    @include font-text();
}

.text-large {
    @include font-large();
}

.text-great {
    @include font-great();
}

.text-big {
    @include font-big();
}

.font-weight {
    font-weight: $font-weight !important;
}

.font-medium {
    font-weight: $font-weight-medium !important;
}

.font-bold {
    font-weight: $font-weight-bold !important;
}

.text-underline {
    text-decoration: underline;
}

.text-uppercase {
    text-transform: uppercase;
}

.font-light {
    font-weight: $font-weight-light !important;
}

.font-bold2 {
    font-weight: $font-weight-bold2 !important;
}

.text-underline-none {
    text-decoration: none;
}