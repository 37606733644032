p.app-mark {
  padding: 2px 12px;
  border-radius: 0 40px 40px 4px;
  background: var(--color-mark-light);
  color: var(--color-blue-mark);

  // &__successfull {
  //   background: var(--color-green-light);
  //   color: var(--color-green);
  // }

  // &__command {
  //   background: var(--color-red-light);
  //   color: var(--color-red);
  // }

  // &__orange {
  //   background: var(--color-orange-light);
  //   color: var(--color-orange);
  // }
}
