.app-card__params {
  width: max-content;
  .app-card__param {
    width: 24px;
    height: 24px;

    &:first-child .app-tooltip__btn {
      border-radius: 4px 0 0 4px;
    }
    &:nth-child(2) .app-dropdown__tooltip {
      border-radius: 0;
    }
    &:last-child .app-dropdown__tooltip {
      border-radius: 0 4px 4px 0;
    }
  }
}
