

$in: 'Inter';
$sans: 'Work Sans';
$dark: #070707;
$sizeindex: calc(1vw + 1vh);

@mixin textStyle($family, $size, $weight, $line-height, $color) {
   font-family: $family;
   font-size: $size;
   font-weight: $weight;
   line-height: $line-height;
   color: $color;
}


.login-container {
   max-width: 70%;
   margin: 0 auto;
}

.btn-submit {
   @include textStyle($in, 16px, 500, 19.36px, #fff);
   background-color: #2BB9FD;
   border: 1px solid #fff;
   border-radius: 40px;
   padding: 4% 7%;
   &:hover {
      background: linear-gradient(90deg, #AA89D0 0%, #FFCC70 100%);
   }
}

// Main page

// Header

header {
   & .header__wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & .header__item:last-child {
         width: 25%;
      }
      & .registration {
         justify-content: flex-end;
      }
      & .header__item:last-child {
         justify-content: flex-end;
      }
      & .header__item {
         width: 55%;
         display: flex;
         align-items: center;
         & nav {
            display: flex;
            align-items: center;
            margin-left: 13%;
            & a {
               @include textStyle($in, 18px, 400, 21.78px, #3F3F3F);
               margin-right: 40%;
            }
         }
         & .logo {
            display: flex;
            align-items: center;
            & img {
               margin-right: 5%;
            }
            & span {
               @include textStyle($sans, 24px, 500, 28.15px, #2d2d2d);
               background: linear-gradient(45deg, #8793D0, #C88ABF, #FFCC70);
               -webkit-background-clip: text;
               -webkit-text-fill-color: transparent;
               text-transform: uppercase;
            }
         }
         // & button {
         //    @include textStyle($in, 16px, 500, 19.36px, #fff);
         //    background-color: #2BB9FD;
         //    border: 1px solid #2BB9FD;
         //    border-radius: 40px;
         //    padding: 4% 7%;
         // }
         & .nonActive {
            @include textStyle($in, 16px, 500, 19.36px, #2BB9FD);
            background-color: transparent;
            border: 1px solid #2BB9FD;
            margin-right: 4%;
            &:hover {
               background: transparent;
            }
         }
      }
   }
}

// Banner

.banner {
   margin-top: 8%;
   & .banner__wrapper {
      width: 100%;
      position: relative;
      & .banner__decor1 {
         position: absolute;
         top: -55px;
         left: -10px;
         z-index: 1;
         & .profiles {
            position: relative;
            & .prof__item {
               position: absolute;
               top: 0;
               left: 0;
               z-index: 2;
               & .dot_1 {
                  position: absolute;
                  top: 45px;
                  left: 195px;
                  z-index: 3;
               }
               & .dot_2 {
                  position: absolute;
                  top: -5px;
                  left: 168px;
                  z-index: 3;
               }
            }
            & .prof__item:first-child {
               top: -350px;
               left: 105px;
            }
            & .prof__item:last-child {
               top: -75px;
               left: 99px;
            }
         }
      }
      & .banner__decor2 {
         position: absolute;
         top: 110px;
         left: 940px;
         z-index: 1;
         & .profiles {
            position: relative;
            & .prof__item {
               position: absolute;
               top: 0;
               left: 0;
               z-index: 2;
               & .dot_1 {
                  position: absolute;
                  top: 17px;
                  left: -10px;
                  z-index: 3;
               }
               & .dot_2 {
                  position: absolute;
                  top: 85px;
                  left: 35px;
                  z-index: 3;
               }
               & .accept {
                  position: absolute;
                  top: 85px;
                  left: 395px;
                  z-index: 3;
               }
            }
            & .prof__item:first-child {
               top: -390px;
               left: 83px;
            }
            & .prof__item:last-child {
               top: -210px;
               left: -30px;
            }
         }
      }
      & h1 {
         width: 70%;
         margin: 0 auto;
         @include textStyle($in, 48px, 600, 58.09px, #3F3F3F);
         text-align: center;
         margin-bottom: 3%;
         & span {
            color: #2BB9FD;
         }
      }
      & p {
         width: 50%;
         margin: 0 auto;
         @include textStyle($in, 16px, 400, 19.36px, #3F3F3F);
         text-align: center;
         margin-bottom: 5%;
      }
      & button{
         display: block;
         margin: 0 auto;
         padding: 1% 1.6%;
         width: 28%;
      }
   }
}

// Desk

.desk {
   margin-top: 15%;
   & .desk__header {
      width: 100%;
      & h1 {
         @include textStyle($in, 36px, 600, 43.57px, #3F3F3F);
         text-align: center;
      }
      & h4 {
         @include textStyle($in, 20px, 400, 24.2px, #3F3F3F);
         text-align: center;
         margin: 2% 0;
      }
      & span {
         @include textStyle($in, 20px, 600, 24.2px, #3F3F3F);
         text-align: center;
         margin: 2% 0;
         display: block;
      }
   }
   & .desc__body {
      display: flex;
      margin-top: 5%;
      & .desc__item {
         .animation {
            animation: showImg 1s linear forwards;
         }
         @keyframes showImg {
            from {
               opacity: 0;
            }
            to {
               opacity: 1;
            }
            }
      }
      & .desc__item:first-child {
         width: 30.33%;
         margin-right: 10%;
         display: flex;
         flex-direction: column;
         & .item__elem:nth-child(3) {
            opacity: .25;
            box-shadow: none;
            color: #3F3F3F;
         }
         & .item__elem {
            box-shadow: none;
            opacity: .8;
            margin-bottom: 5%;
            padding: 3% 3% 3% 6%;
            border-radius: 8px;
            cursor: pointer;
            & h5 {
               @include textStyle($in, 16px, 500, 19.36px, #000);
               margin-bottom: 2%;
               width: 82%;
            }
            & p {
               @include textStyle($in, 16px, 400, 19.36px, #000);
               width: 94%;
            }
         }
         & .active {
            position: relative;
            opacity: 1;
            box-shadow: 0px 2px 5px 2px #00000026;
            &::after {
               position: absolute;
               content: '';
               top: 0;
               left: 0;
               z-index: 1;
               height: 100%;
               width: 8px;
               background-color:#5D43AA;
               border-radius: 8px 0px 0px 8px;
            }
         }
      }
   }
}

// Tasks

// .tasks {
//    margin-top: 8%;
//    & h1 {
//       @include textStyle($in, 36px, 600, 43.57px, #3F3F3F);
//       text-align: center;
//       margin-bottom: 5%;
//    }
//    & .tasks__body {
//       display: flex;
//       align-items: center;
//       margin: 5% 0;
//       & .tasks__item:nth-child(2) {
//          position: relative;
//          &::after {
//             position: absolute;
//             content: '';
//             top: 40%;
//             left: 0;
//             z-index: 1;
//             background: url(../img/tasks/arrow.svg) no-repeat;
//             width: 100px;
//             height: 100px;
//          }
//          &::before {
//             position: absolute;
//             content: '';
//             top: 40%;
//             left: 100%;
//             z-index: 1;
//             background: url(../img/tasks/arrow.svg) no-repeat;
//             width: 100px;
//             height: 100px;
//          }
//       }
//       & .tasks__item {
//          width: 100%;
//          display: flex;
//          flex-direction: column;
//          align-items: center;
//          & h3 {
//             @include textStyle($in, 24px, 500, 29.05px, #3F3F3F);
//             text-align: center;
//             margin: 7% 0 3% 0;
//          }
//          & h5 {
//             @include textStyle($in, 16px, 400, 19.36px, #3F3F3F);
//             text-align: center;
//          }
//       }
//    }
//    & button {
//       display: block;
//       margin: 0 auto;
//       padding: 1% 1.6%;
//       width: 25%;
//    }
// }

// Slider

.slider {
   margin: 8% 0 15%;
   position: relative;
   & .right {
      position: absolute;
      top: 60%;
      left: 85%;
      z-index: 1;
      cursor: pointer;
   }
   & .left {
      position: absolute;
      top: 60%;
      left: 12%;
      z-index: 1;
      cursor: pointer;
   }
   & .swiper-scrollbar {
      position: absolute;
      top: 120%;
      left: 33%;
      z-index: 1;
      width: 58%;
      cursor: pointer;
      background-color: transparent;
      & .swiper-scrollbar-drag {
         height: 100%;
         width: 300px !important;
         background: #3F3F3F;
         border-radius: 0 12px 12px 0;
      }
   }
   & h1 {
      @include textStyle($in, 36px, 600, 43.57px, #3F3F3F);
      text-align: center;
      margin-bottom: 1%;
   }
   & p {
      @include textStyle($in, 16px, 400, 19.36px, #3F3F3F);
      text-align: center;
      width: 40%;
      margin: 0 auto;
   }
   & .slider__body {
      position: relative;
      margin-top: 6%;
      overflow: hidden;
      // overflow: hidden;
      // &::after {
      //    position: absolute;
      //    top: 130%;
      //    left: 25%;
      //    content: '';
      //    width: 299px;
      //    height: 4px;
      //    background-color: #3f3f3f;
      //    z-index: 1;
      //    border-radius: 8px 0 0 8px;
      // }
      & .slider__item {
         width: 100%;
         display: flex;
         flex-direction: column;
         align-items: center;
         & h4 {
            @include textStyle($in, 18px, 400, 21.78px, #000);
            text-align: center;
            margin-top: 17%;
            width: 75%;
         }
      }
   }
}

// Footer 
footer {
   & .requisites {
      width: 100%;
      background-color: #222930;
      padding: 2% 0 3% 0;
      & .requisites__body {
         display: flex;
         & .requisites__column:last-child {
            & .requisites__link {
               & a {
                  width: 55%;
               }
            }
         }
         & .requisites__column {
            width: 100%;
            & h5 {
               @include textStyle($in, 18px, 600, 21.78px, #fff);
               margin-bottom: 7%;
            }
            & .requisites__link {
               display: flex;
               align-items: center;
               margin-bottom: 5%;
               // width: 57%;
               & a {
                  @include textStyle($in, 12px, 400, 14.52px, #9FB1C4);
                  margin-right: 5%;
               }
               & span {
                  @include textStyle($in, 12px, 400, 14.52px, #fff);
                  margin-right: 5%;
                  padding: 1% 3.5%;
                  border-radius: 40px;
                  background: linear-gradient(90deg, #AA89D0 0%, #FFCC70 100%);
               }
            }
         }
      }
   }
   & .social {
      width: 100%;
      background-color: #1A2027;
      padding: 2% 0 3% 0;
      & .social__row {
         display: flex;
         align-items: center;
      }
      & .social__row:first-child {
         justify-content: space-between;
         margin-bottom: 5%;
         & .logo {
            display: flex;
            align-items: center;
            & img {
               margin-right: 5%;
            }
            & span {
               @include textStyle($sans, 24px, 500, 28.15px, #2d2d2d);
               background: linear-gradient(45deg, #8793D0, #C88ABF, #FFCC70);
               -webkit-background-clip: text;
               -webkit-text-fill-color: transparent;
               text-transform: uppercase;
            }
         }
         & .social__wrapper {
            display: flex;
            align-items: center;
            & a {
               margin-right: 12%;
            }
         }
      }
      & .social__row:last-child {
         display: flex;
         justify-content: space-between;
         width: 50%;
         & .copyright {
            @include textStyle($in, 16px, 400, 19.36px, #8A8A8A);
         }
         & .links {
            display: flex;
            width: 78%;
            & a {
               @include textStyle($in, 12px, 400, 14.52px, #8A8A8A);
               margin-right: 7%;
            }
         }
      }
   }
}


// Registration page

// Form

.form {
   margin-top: 5%;
   & form {
      display: block;
      width: 23%;
      margin: 0 auto;
      padding: 1.5% 2%;
      box-shadow: 0px 0px 4px 1.4px #00000040;
      border-radius: 12px; 
      & h1 {
         @include textStyle($in, 24px, 600, 29.05px, #000);
      }
      & .input {
         width: 100%;
         margin-top: 10%;
         position: relative;
         & img {
            position: absolute;
            top: 8%;
            left: 95%;
            z-index: 1;
            cursor: pointer;
         }
         & input {
            padding: 1.5% 0;
            opacity: .8;
            width: 100%;
            border: none;
            border-bottom: 2px solid #A5A5A5;
            @include textStyle($in, 16px, 600, 19.36px, #0b0b0b);
         }
         & span {
            @include textStyle($in, 12px, 600, 14.52px, #A5A5A5);
            margin-top: 2%;
            display: block;
         }
      }
      & .submit {
         width: 100%;
         & .btn-submit {
            width: 72%;
            display: block;
            margin: 0 auto;
            margin-top: 10%;
            margin-bottom: 5%;
         }
         & p {
            margin-bottom: 10%;
            @include textStyle($in, 12px, 400, 14.52px, #A5A5A5);
            & a {
               color: #152CAD;
            }
         }
      }
      & .signWith {
         width: 100%;
         margin-bottom: 5%;
         & h5 {
            @include textStyle($in, 12px, 400, 14.52px, #A5A5A5);
            text-align: center;
            margin-bottom: 5%;
         }
         & .signWith__links {
            display: flex;
            align-items: center;
            justify-content: space-between;
            & a {
               display: flex;
               align-items: center;
               @include textStyle($in, 12px, 400, 14.52px, #000);
               padding: 1.5% 7.5%;
               border-radius: 40px;
               border: 1px solid #A5A5A5;
               & img {
                  margin-right: 8%;
               }
            }
         }
      }
   }
}


// Dashboard page

// Form

.dashboard {
   margin: 5% 0 7%;
   overflow: hidden;
   & .slider {
      width: 80%;
      margin: 0 auto;
      & h1 {
         @include textStyle($in, 48px, 600, 58.09px, #000);
         margin-bottom: 5%;
         text-align: center;
      }
      & .image-slider {
         padding: 30px 0 70px 0;
         position: relative;
         & .next {
            position: absolute;
            top: 50%;
            left: 100%;
            z-index: 1;
            cursor: pointer;
         }
         & .prev {
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 1;
            cursor: pointer;
         }
         & .image-slider__wrapper {
            & .image-slider__slide {
               & .image-slider__image {
                  text-align: center;
                  & img {
                     max-width: 100%;
                  }
               }
            }
         }
      }
   }
   & .dashboard__tasks {
      width: 41%;
      margin: 0 auto;
      & h2 {
         @include textStyle($in, 32px, 600, 38.73px, #3F3F3F);
         text-align: center;
      }
      & .tasks__body {
         display: flex;
         width: 80%;
         margin: 5% auto;
         & p {
            @include textStyle($in, 16px, 600, 19.36px, #3F3F3F);
            width: 43%;
         }
         & ul {
            width: 40%;
            margin-left: 10%;
            display: flex;
            flex-direction: column;
            & li {
               display: flex;
               align-items: center;
               margin-bottom: 5%;
               & span {
                  @include textStyle($in, 16px, 400, 19.36px, #3F3F3F);
                  margin-left: 5%;
                  text-align: left
               }
            }
         }
      }
      & span {
         @include textStyle($in, 16px, 600, 19.36px, #3F3F3F);
         text-align: center;
         width: 90%;
         display: block;
         margin: 0 auto;
      }
   }
}

// Rates  page

// Rates

.rates {
   margin-top: 5%;
   margin-bottom: 7%;
   & .rates__header {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      & h1 {
         @include textStyle($in, 48px, 600, 58.09px, #3F3F3F);
         text-align: center;
      }
      & p {
         @include textStyle($in, 16px, 400, 19.36px, #3F3F3F);
         text-align: center;
         margin: 3% 0;
         width: 50%;
      }
   }
   & .rates__body {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 4%;
      & .rates__item:first-child {
         & .rates__saving {
            opacity: 0;
         }
      }
      & .rates__item {
         border-radius: 12px;
         box-shadow: 0px 0px 4px 1.5px #00000040;
         padding: 4% 2%;
         width: 32%;
         & h5 {
            @include textStyle($in, 18px, 600, 21.78px, #000);
            margin-bottom: 3%;
         }
         & .rates__price {
            display: flex;
            & h4 {
               @include textStyle($in, 32px, 500, 38.73px, #000);
            }
            & span {
               margin-top: 2%;
               margin-left: 3%;
               @include textStyle($in, 24px, 500, 29.05px, #000);
            }
         }
         & .rates__saving {
            display: flex;
            align-items: center;
            @include textStyle($in, 16px, 500, 19.36px, #2BB9FD);
            margin: 3% 0;
            & span {
               margin-left: 3%;
            }
         }
         & .rates__conditions {
            @include textStyle($in, 16px, 400, 19.36px, #3F3F3F);
         }
         & ul {
            display: flex;
            flex-direction: column;
            margin-top: 20%;
            & li {
               display: flex;
               flex-direction: column;
               margin-bottom: 5%;
               width: 80%;
               & .tesis {
                  display: flex;
                  align-items: center;
                  & img {
                     margin-right: 3%;
                  }
                  & p {
                     @include textStyle($in, 16px, 400, 19.36px, #3F3F3F);
                  }
               }
               & .subcondition {
                  @include textStyle($in, 12px, 400, 14.52px, #C1BABA);
                  margin-left: 7%;
                  margin-top: 3%;
               }
            }
         }

      }
   }
   & button {
      display: block;
      margin: 0 auto;
      @include textStyle($in, 18px, 500, 21.78px, #fff);
      padding: 1.2% 1%;
      width: 14%;
   }
}

// Blog  page

// Blog

.blog {
   margin: 5% 0;
   & h1 {
      @include textStyle($in, 48px, 600, 58.09px, #3F3F3F);
      text-align: center;
      margin-bottom: 1.5%;
   }
   & .blog__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      & .blog__item {
         width: 32.5%;
         margin-bottom: 2.6%;
         & .blog__preview {
            width: 100%;
            height: 45%;
            overflow: hidden;
            border-radius: 12px 12px 0 0;
            & img {
               object-fit: cover;
               width: 100%;
               height: 100%;
            }
         }
         & .blog__content {
            width: 100%;
            padding: 4%;
            border-bottom: 1px solid #3F3F3F;
            & .blog__subtitle {
               @include textStyle($in, 12px, 500, 14.52px, #2BB9FD);
            }
            & .blog__title {
               @include textStyle($in, 16px, 500, 19.36px, #000);
               margin: 3% 0;
               width: 80%;
            }
            & .blog__description {
               @include textStyle($in, 14px, 400, 16.94px, #000);
               width: 84%;
            }
         }
         & a {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4%;
            & span {
               @include textStyle($in, 12px, 500, 14.52px, #000);
            }
         }
      }
   }
}

// Open blog  page

// Open blog

.openBlog {
   width: 70%;
   margin: 5% auto;
   & .openBlog__content {
      width: 100%;
      & .openBlog__info {
         @include textStyle($in, 12px, 500, 18px, #000);
         text-transform: uppercase;
         & span {
            display: flex;
            align-items: center;
            & a {
               color: #2BB9FD;
               margin: 0 .4%;
            }
         }
      }
      & .openBlog__title {
         @include textStyle($in, 30px, 600, 45px, #000);
         margin: 1% 0;
         width: 80%;
      }
      & .openBlog__subTitle {
         @include textStyle($in, 20px, 600, 30px, #B6B6B6);
         width: 82%;
      }
      & img {
         border-radius: 16px;
         margin: 4% 0;
      }
      & .openBlog__text {
         @include textStyle($in, 18px, 400, 27px, #000);
         & p {
            margin-bottom: 2%;
            width: 85%;
         }
         & h3 {
            @include textStyle($in, 24px, 600, 29.05px, #000);
            margin-top: 7%;
            margin-bottom: 3%;
         }
         & ul {
            margin-top: 3%;
            margin-left: 3%;
            & li {
               width: 85%;
               list-style-type: disc;
               color: #000;
               & span {
                  font-weight: 600;
               }
            }
         }
      }
   }
}