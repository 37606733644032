.app-select {
  min-width: 250px;
  border: 1px solid var(--color-grey-avatar);
  &__title {
    height: 40px;
    &::after {
      content: "";
      position: absolute;
      right: 16px;
      width: 16px;
      height: 16px;
      background: url('../../assets/menu/arrow.svg') no-repeat center;
      transition: .6s;
      background-size: cover;
    }
    &::after {
      transform: rotate(0deg);
    }
  }
  &__content {
    position: absolute;
    overflow: hidden;
    will-change: transform;
    transition: transform .3s;
    transform: scaleY(0);
    transform-origin: 50% 0;
    z-index: 9;
    box-shadow: 0 2px 6px 0 #00000026;
    border-radius: 4px;
    background: #fff;
    max-height: 360px;


    &.select__checkboxShow{
      will-change: transform;
      transition: transform .3s;
      transform: scaleY(1);
      transform-origin: 50% 0;
      z-index: 999;
    }

    input {
      display: none;
    }

    label {
      &:hover{
        color: var(--color-blue);
      }
    }
    // input:checked  + label{
    //   color: var(--color-blue);
    // }
  }
}

