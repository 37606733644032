.app-tabs {
  &__top {
    background: #234D84AA;
    width: max-content;
    border-radius: 4px;

    p {
      padding: 6px 12px;
    }

    p.active {
      background: #FFFFFF;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25), 0px -1px 5px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
    }
  }

  &__content {
    opacity: 0;
    z-index: -1;
    height: 0;

    &.active {
      height: 100%;
      opacity:1;
      z-index: 1;
      transition: .3s;
    }
  }
}

.scroll-x {
  overflow-x: auto;
}

.scroll-y {
  overflow-y: auto;
}

.overflow-visible-x {
  overflow-x: visible;
}

.overflow-visible-y {
  overflow-y: visible;
}

.overflow-hidden-x {
  overflow-x: hidden;
}

.overflow-hidden-y {
  overflow-y: hidden;
}