.app-dropdown {
  &__overlay {
    border-radius: 4px;
    box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.25), 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    right: -24px;
    top: calc(100% + 8px);
    background: #ffffff;

    transition: opacity .4s;
    opacity: 0;
    z-index: -1;
    width: 0;
    height: 0;
    overflow: hidden;

    &.open {
      opacity: 1;
      z-index: 5;
      width: 265px;
      height: 0;
      height: auto;
    }
  }

  &__content {
    transition: opacity .4s;
    position: relative;
    &.hidden {
      opacity: 0;
      z-index: -1;
      width: 0;
      height: 0;
    }

    p {
      &:hover {
        background: var(--color-grey-border5);
      }
    }
  }

  &__subcontent {
    transition: opacity .4s;
    opacity: 0;
    z-index: -1;
    width: 0;
    height: 0;
    &.open {
      opacity: 1;
      z-index: 5;
      width: max-content;
      height: 0;
      height: auto;
      position: relative;
    }
  }

  &__overlay-avatar {

    right: 0;
    &.open {
      width: 150px;
    }
    p {
      padding: 6px 24px;
    }
    svg {
      path {
        stroke: var(--color-primary);
      }
    }
  }
}
.app-dropdown__task {
  .app-dropdown__overlay {
    &.open {
      width: 270px;
    }
  }
}
.app-dropdown__status {
  .app-dropdown__overlay {

    right: 0;
    left: 0;
    &.open {
      width: 141px;
    }
  }
}

.app-dropdown__subtask {
  .app-dropdown__overlay {

    &.open {
      width: 190px;
    }
  }
}

.app-dropdown__priority,
.app-dropdown__mark,
.app-dropdown__priority2 {
  .app-dropdown__open {
    svg {
      transition: .2s;
    }
    &:hover {
      svg{
        rect {
          stroke: var(--color-blue-btn);
        }
      }
    }

  }
  .app-dropdown__overlay {

    right: 0;
    left: 0;
    &.open {
      width: 141px;
    }
  }
}

.app-dropdown__mark,
.app-dropdown__prioity2 {
  .app-dropdown__overlay {

    &.open {
      width: 227px;
    }
  }
  .app-dropdown__tooltip {
    width: 24px;
    height: 24px;
    outline: 0.5px solid #878787;
    border-radius: 4px;
    box-sizing: border-box;
    border-collapse: collapse;
  }
  .app-dropdown__open {
    &:hover {
      .app-dropdown__tooltip {
        outline: 1px solid #152CAD;
      }
    }
    &.active {
      .app-dropdown__tooltip {
        outline: 1px solid #2774E3;
        background: #2BB9FD;
        transition: .2s;

        svg {
          path {
            fill: #2BB9FD;
            stroke: #ffffff;
          }
        }
      }
    }
  }
}

.app-dropdown__data {
  .app-dropdown__overlay {

    left: calc(100% + 12px);
    top: 0;
    &.open {
      width: 250px;
    }
  }
}

.app-dropdown__avatar {
  .app-dropdown__overlay {
    &.open {
      width: 231px;
    }
  }
  &-search {
    background: var(--color-grey-input2);
  }
}

.app-dropdown__prioity-square {
  .app-dropdown__tooltip {
    border-radius: 0;
  }
}

.app-dropdown__status-bg {
  .app-dropdown__overlay {
    width: 100%;
    right: 0;
  }
}

.app-dropdown__delete {
  .app-dropdown__overlay {
    right: 0;
    &.open {
      width: max-content;
    }
  }
}

.app-dropdown__role {
  .app-dropdown__overlay {
    right: auto;
    left: 0;
    &.open {
      width: max-content;
    }
  }
}

.app-dropdown__user-add {

  .app-dropdown__overlay {
    right: 0;
    max-height: 300px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-grey-avatar);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--color-grey-avatar);
    }
    &.open {
      width: 430px;

    }
  }

  .app-command__table.overflow-auto {
    max-height: 193px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-grey-avatar);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--color-grey-avatar);
    }
  }
}

.app-dropdown__team {
  border-radius: 4px;

  &.active {
    outline: 2px solid rgba(30, 62, 254, 0.15);
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.05)) drop-shadow(0px -2px 6px rgba(0, 0, 0, 0.05));
  }
  .app-dropdown__open {
    border-radius: 4px;
    border: 1px solid #fff;
    svg {
      transition: .3s;
    }

    &:hover {
      border: 1px solid var(--color-blue);
    }
    &.active {
      border: 1px solid var(--color-blue);

      svg {
        transform: rotate(-180deg);
      }
    }
  }

  .app-dropdown__overlay-avatar.open {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15), 0px -2px 6px rgba(0, 0, 0, 0.15);
  }
}

.app-dropdown__profile {
  .app-dropdown__overlay.open {
    width: 100%;
    right: 0;
  }
}

.shadow-overlay {
  box-shadow: inset 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
}

.overlay-team {
    max-height: calc(100vh - 160px);
}