.page-cabinet {

  &-bg {
    width: 100%;
    height: 100vh;
    background: linear-gradient(74deg, #234D84 0%, #22A5F5 125.54%);
    position: fixed;

  }

  &__header {
    background: #fff;
    padding: 10px 20px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 3;

    a {
      color: #3B607F;
      text-decoration: none;
    }
  }

  &__sidebar {
    background: #fff;
    border-radius: 4px;
    width: 320px;
    position: sticky;
    left: 0;
    top: 73px;
    min-height: 464px;
  }

  &__contents {
    background: #fff;
    border-radius: 4px;
    width: calc(100% - 360px);
  }

  &__content {
    opacity: 0;
    z-index: -1;
    height: 0;
    overflow: hidden;

    &.active {
      height: 100%;
      opacity:1;
      z-index: 1;
      transition: .3s;
      overflow: inherit;
    }
  }
}

.app-table__cabinet-col {
  &:nth-child(1) {
    width: 100%;
  }
  &:nth-child(2) {
    width: 200px;

  }
  &:nth-child(3) {
    width: 140px;
  }
  &:nth-child(4) {
    width: 160px;
  }
}

.app-table__cabinet-blue {
  background: var(--color-blue);
}

.page-cabinet__textarea {
  border: 1px solid #E0E0E0;
  height: 320px;
  resize: none;
  border-radius: 4px;
  font-size: 14px;
  line-height: 17px;
  color: #A6A6A6;

}

.page-cabinet__textarea-symbol {
  position: absolute;
  right: 40px;
  bottom: 100px;
  color: #A6A6A6;
}

.page-cabinet__textarea-btn {
  position: absolute;
  right: 40px;
  bottom: 40px;
  background: rgba(30, 62, 254, 0.65);
}

.page-cabinet__sidebar-item {
  color: var(--color-cabinet);

  svg {
    path{
      stroke: var(--color-cabinet);
    }
  }

  &.active {
    color: var(--color-blue);

    svg {
      path{
        stroke: var(--color-blue);
      }
    }
  }
}
