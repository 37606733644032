$app-menu-width: 56px;
.app-menu-width {
    width: $app-menu-width;
}
.app-menu {
    background: var(--color-blue-dark);
    width: $app-menu-width;
    height: 100vh;
    z-index: 6;
    position: fixed;

    &__team {
        width: 36px;
        height: 36px;
        color: #fff;
        font-weight: $font-weight-bold2;
        opacity: 0.5;
        border-radius: 4px;
        transition: 0.2s;
        background: #fff;
        text-decoration: none;
        &:hover {
            opacity: 1;
        }
    }
}

.app-tooltip__visible .app-menu__team {
    opacity: 1;
}

.app-menu__circle {
    .app-menu__team {
        border-radius: 50%;
        font-weight: $font-weight;
    }
}
