.app-card {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid var(--color-grey-border-card);
  border-left: 4px solid #3D27E3;

  &__title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__avatar {
    width: 24px;
    height: 24px;
    color: #ffffff;
    border-radius: 50%;
  }

  &__add-task {
    .app-border2 {
      width: calc(100% + 24px);
      margin-left: -12px;
    }
    &.hidden {
      opacity: 0;
      z-index: -1;
      width: 0;
      height: 0;
      position: relative;
    }
  }

  &__subtask {
    border: 1px solid var(--color-grey-task);
    border-radius: 4px;
    padding: 2px 4px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__subtask-line {
    width: 1px;
    height: 12px;
    background: var(--color-grey-task);
  }

  &--work {
    border-left: 4px solid var(--color-error-text);
  }

  &--review {
    border-left: 4px solid var(--color-success);
  }

  &--close {
    border-left: 4px solid var(--color-notification);
  }
}

.card-upgrade {
  border: 2px solid var(--color-grey-border-card);
  border-radius: 16px;
  max-width: 350px;
  background-color: #f9f9f9;
}

.card-deactivated {
  opacity: 0.85; 
  // background-color: #eee; 
  pointer-events: none; 
  
  // color: #888; 
}

.card-active {
  border: 2px solid var(--color-blue2);
}

.button-deactivated {
  // background-color: #eee;
  opacity: 0.6; 
}

.app-card__subtask {
  transition: 0.2s;

  &-text {
    color: var(--color-grey-task);
  }
  &:hover {
    .app-card__subtask-text {
      color: var(--color-blue-btn);
    }

    .app-card__subtask-icon svg {
      path {
        fill: var(--color-blue-btn);
      }
    }
  }

  .app-tooltip svg {
    width: 20px;
    height: 20px;
    display: flex;
    flex-shrink: 0;
  }
}

.app-card__subtask-wrapper {
  transition: 0.4s;
  width: 78px;

  &.hidden {
    width: 0;
  }
}

.app-card__subtasks-wrapper {
  -moz-transition: max-height 0.4s ease;
  -webkit-transition: max-height 0.4s ease;
  -o-transition: max-height 0.4s ease;
  transition: max-height 0.4s ease;
  overflow: hidden;
}

.app-card__subtasks {
  .app-border2 {
    width: calc(100% + 24px);
    margin-left: -12px;
  }
}

.max-height-0 {
  max-height: 0;
}




