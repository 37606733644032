$font-primary: 'Inter', sans-serif;

$font-weight: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-light: 300;
$font-weight-bold2: 700;

$font-style: normal;

$spacers: (
        0: 0,
        1: 4px,
        2: 8px,
        3: 10px,
        4: 12px,
        5: 16px,
        6: 24px,
        7: 40px,
        8: 60px,
        9: 20px,
        10: 6px,
);

$grid-breakpoints: (
        xs: 0,
        lg: 1920px
);

$container-max-widths: (
        lg: 1700px,
);

$grid-column: ();

$grid-gutter-width: 24px;


