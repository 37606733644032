
@mixin font-h1() {
  font-size: 50px;
  line-height: 64px;
}

@mixin font-h2() {
  font-weight: $font-weight-bold;
  font-size: 32px;
  line-height: 39px;
}

@mixin font-h3() {
  font-weight: $font-weight-medium;
  font-size: 24px;
  line-height: 29px;
}


@mixin font-title-large() {
  font-size: 48px;
}

@mixin font-big() {
  font-size: 24px;
}

@mixin font-great() {
  font-size: 20px;
}

@mixin font-large() {
  font-size: 18px;
}

@mixin font-medium() {
  font-size: 16px;
}

@mixin font-text() {
  font-size: 15px;
  line-height: 24px;
}

@mixin font-basic() {
  font-size: 14px;
}

@mixin font-little() {
  font-size: 12px;
}

@mixin font-small() {
  font-size: 10px;
}